<template>
  <div>
    <form class="form" @submit.prevent="addReceiver" novalidate="true" autocomplete="off">
      <div class="form__group--three">
        <label for="amount" class="form__label">
          <input
            type="tel"
            id="amount"
            name="amount"
            placeholder="Amount"
            class="form__input"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @focus="showAmounts = true"
            v-model.lazy="$v.form.amount.$model"
            :class="{
              'is-invalid': submitted && $v.form.amount.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.amount.required"
          >Account number is required</small
        >
        <br />
        <small class="error" v-if="submitted && !$v.form.amount.numeric"
          >Needs to be numeric only.</small
        >
      </div>

      <app-amount-suggest
        v-if="showAmounts"
        @selectedAmount="setAmount"
        type="localTransfer"
      ></app-amount-suggest>

      <div class="form__group--three">
        <label for="transferSource" class="form__label">
          <input
            readonly
            type="tel"
            id="transferSource"
            name="transferSource"
            placeholder="From"
            class="form__input hide-caret"
            @focus="showFromModal = true"
            autocomplete="off"
            v-model.lazy="$v.form.transferSource.$model"
            :class="{
              'is-invalid': submitted && $v.form.transferSource.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.transferSource.required"
          >Source account is required</small
        >
        <img src="../../../assets/images/down-arrow.svg" alt="dropdown arrow" />
      </div>

      <div class="form__group--three">
        <label for="destinationAccount" class="form__label">
          <input
            readonly
            type="tel"
            id="destinationAccount"
            name="destinationAccount"
            placeholder="To"
            class="form__input hide-caret"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @focus="showToModal = true"
            v-model.lazy="$v.form.destinationAccount.$model"
            :class="{
              'is-invalid': submitted && $v.form.destinationAccount.$error,
            }"
        /></label>
        <small class="error" v-if="submitted && !$v.form.destinationAccount.required"
          >Destination account is required</small
        >
        <img src="../../../assets/images/down-arrow.svg" alt="dropdown arrow" />
      </div>

      <div class="form__group--three">
        <label for="budgetCategory" class="form__label">
          <select
            name="budgetCategory"
            id="budgetCategory"
            class="form__input form__select"
            v-model.lazy="form.budgetCategory"
          >
            <option disabled value="">Budget Category</option>
            <option
              v-for="(category, index) in categories"
              :value="category.value"
              :key="index"
            >
              {{ category.text }}
            </option>
          </select>
        </label>
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loadingTwo" />
        <span>Add Beneficiary</span>
      </button>
    </form>

    <form
      v-if="receiverAccounts.length > 0"
      class="form"
      @submit.prevent="submitForm"
      novalidate="true"
      autocomplete="off"
    >
      <div
        class="bulk__container"
        v-loading="loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <section
          v-for="(trans, index) in receiverAccounts"
          :key="index"
          class="bulk-list"
        >
          <div class="bulk-list__group">
            <h4>Recipient {{ index + 1 }}</h4>
            <i class="el-icon-close" @click="removeReceiver(index)"></i>
          </div>
          <div class="bulk-list__group">
            <h3>
              {{ trans.beneficiaryDetails.NUBAN }}
            </h3>
            <h3>{{ trans.receiverBank }}</h3>
          </div>
          <h2>{{ trans.beneficiaryDetails.AccountTitle }}</h2>
          <h2>{{ trans.amount | formatCurrency }}</h2>
        </section>
      </div>

      <div
        class="bulk-list__two"
        v-loading="loadingTwo"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <div class="charges">
          <div>
            <h4>VAT:</h4>
            <p>{{ formatAmount(fees.vat) }}</p>
          </div>
          <div>
            <h4>FEE:</h4>
            <p>{{ formatAmount(fees.feeAmount) }}</p>
          </div>
          <div>
            <h4>TOTAL:</h4>
            <p>{{ formatAmount(fees.sumTotal) }}</p>
          </div>
        </div>
      </div>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Send Money</span>
      </button>
    </form>

    <app-source-accounts
      v-if="showFromModal"
      @sourceDetails="setSourceDetails"
      :showFromModal="showFromModal"
      @close="showFromModal = false"
    ></app-source-accounts>

    <app-destination-account
      v-if="showToModal"
      @destinationDetails="setDestinationDetails"
      :showToModal="showToModal"
      :filteredUserAccounts="filteredUserAccounts"
      @close="showToModal = false"
    ></app-destination-account>

    <app-pin-modal
      ref="otpInput"
      v-if="showPinModal"
      :showPinModal="showPinModal"
      @close="showPinModal = false"
      @userPin="setUserPin"
    ></app-pin-modal>
  </div>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";

import AmountSelector from "@/components/Transfers/Widgets/AmountSelector.vue";
import SourceAccounts from "@/components/Transfers/Widgets/SourceAccounts.vue";
import DestinationAccount from "@/components/Transfers/LocalTransfers/DestinationAccount.vue";
import PinModal from "@/widgets/PinModal.vue";

export default {
  components: {
    "app-amount-suggest": AmountSelector,
    "app-source-accounts": SourceAccounts,
    "app-destination-account": DestinationAccount,
    "app-pin-modal": PinModal,
  },
  computed: mapState(["userAccounts", "user", "loading"]),
  data() {
    return {
      passwordFieldType: "password",
      loadingTwo: false,
      submitted: false,
      showAmounts: false,
      selectedAccount: "",
      showFromModal: false,
      showToModal: false,
      showDateTimeModal: false,
      showDateTimeRangeModal: false,
      showPinModal: false,
      duplicateStatus: false,
      filteredUserAccounts: [],
      receiverAccounts: [],
      receiverAccountsBulkSterling: [],
      receiverAccountsBulkOthers: [],
      form: {
        amount: "",
        transferSource: "",
        accountType: "",
        debitNuban: "",
        debitCurrency: "",
        budgetCategory: "General",
        isFromWallet: false,
        isToWallet: false,
        sendToMySelf: false,
        isFx: false,
        pin: "",
      },
      fees: {
        feeAmount: 0,
        vat: 0,
        totalcharge: 0,
        sumTotal: 0,
      },
      categories: [
        { text: "General", value: "General" },
        { text: "Family", value: "Family" },
        { text: "Bills", value: "Bills" },
        { text: "Eating Out", value: "Eating Out" },
        { text: "Entertainment", value: "Entertainment" },
        { text: "Expenses", value: "Expenses" },
        { text: "Finances", value: "Finances" },
        { text: "Groceries", value: "Groceries" },
        { text: "Health", value: "Health" },
        { text: "Holidays", value: "Holidays" },
        { text: "Shopping", value: "Shopping" },
        { text: "Transport", value: "Transport" },
      ],
    };
  },
  validations: {
    form: {
      amount: { required, numeric },
      transferSource: { required },
      destinationAccount: { required },
    },
  },
  watch: {
    selectedAccount: function (newValue) {
      if (newValue !== "") {
        // this.form.transferSource = `${newValue.NUBAN} | ${newValue.ACCT_TYPE}`;
        this.filteredUserAccounts = this.userAccounts.filter((item) => item !== newValue);
      }
    },
    "form.accountType": function (newValue) {
      if (newValue === "Wallet") {
        this.form.isFromWallet = true;
      } else {
        this.form.isFromWallet = false;
      }
    },
    "form.creditNuban": function (newValue) {
      if (newValue.length === 11) {
        this.form.isToWallet = true;
      } else {
        this.form.isToWallet = false;
      }
    },
    "form.debitCurrency": function (newValue) {
      if (newValue !== "NGN" && newValue !== "") {
        this.form.isFx = true;
      } else {
        this.form.isFx = false;
      }

      this.userAccounts.forEach((item) => {
        if (item.debitNUBAN === this.form.creditNuban) {
          this.form.sendToMySelf = true;
        } else {
          this.form.sendToMySelf = false;
        }
      });
    },
    showPinModal: function (newValue) {
      if (newValue === true) {
        this.$nextTick(() => {
          this.fixChromeMask();
        });
      }
    },
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    setAmount(value) {
      this.form.amount = value;
      this.showAmounts = false;
    },
    setSourceDetails(account) {
      this.form.debitNuban = account.NUBAN;
      this.form.debitCurrency = account.Currency_Code;
      this.form.accountType = account.ACCT_TYPE;
      this.selectedAccount = account;

      this.form.transferSource = `${account.NUBAN} | ${account.ACCT_TYPE}`;

      this.showFromModal = false;
    },
    setDestinationDetails(value) {
      this.showToModal = false;
      this.form = { ...this.form, ...value };
    },
    handleIntrabankBulkPayment(receivers) {
      let payload = {
        TransactionPin: this.form.pin,
        smId: this.user.profileInfo.SMID,
        PaymentData: [...receivers],
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleIntrabankBulkPayment(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.handleIntrabankBulkPayment();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleInterbankBulkPayment(receivers) {
      let payload = {
        TransactionPin: this.form.pin,
        smId: this.user.profileInfo.SMID,
        PaymentData: [...receivers],
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleInterbankBulkPayment(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Success",
              {
                confirmButtonText: "OK",
                type: "success",
                dangerouslyUseHTMLString: true,
              }
            );
            this.$store.dispatch("handleGetUserAccounts");
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.handleInterbankBulkPayment();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    handleJointBulkPayment(intraReceivers, interReceivers) {
      let payload = {
        TransactionPin: this.form.pin,
        smId: this.user.profileInfo.SMID,
        PaymentData: [...intraReceivers],
      };

      this.$store.commit("toggleLoading", true);

      api
        .handleInterbankBulkPayment(payload)
        .then((response) => {
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          let responseCode = response.Code;

          if (responseStatus) {
            let payload = {
              TransactionPin: this.form.pin,
              smId: this.user.profileInfo.SMID,
              PaymentData: [...interReceivers],
            };

            api
              .handleInterbankBulkPayment(payload)
              .then((response) => {
                let responseStatus = response.Status;
                let responseMessage = response.Message;
                if (responseStatus) {
                  this.$alert(
                    `${
                      responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                    }`,
                    "Success",
                    {
                      confirmButtonText: "OK",
                      type: "success",
                      dangerouslyUseHTMLString: true,
                    }
                  );
                  this.$store.dispatch("handleGetUserAccounts");
                }
              })
              .catch((error) => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  showClose: true,
                  message: `${error}`,
                  type: "error",
                  duration: 10000,
                });
              });
          } else if (!responseStatus && responseCode === "FTDUP001") {
            this.$confirm(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Warning",
              {
                confirmButtonText: "Continue",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            )
              .then(() => {
                this.duplicateStatus = true;
                this.sendSterlingToInterbank();
              })
              .catch(() => {
                this.$store.commit("toggleLoading", false);
                this.$message({
                  type: "info",
                  showClose: true,
                  message: "Transaction canceled",
                  duration: 10000,
                });
              });
          } else {
            this.$store.commit("toggleLoading", false);
            this.$alert(
              `${responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)}`,
              "Error",
              {
                confirmButtonText: "OK",
                type: "error",
                dangerouslyUseHTMLString: true,
              }
            );
          }
        })
        .catch((error) => {
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `${error}`,
            type: "error",
            duration: 10000,
          });
        });
    },
    fixChromeMask() {
      // Modal mask fix for chrome
      this.$refs.otpInput.$el.children[0].setAttribute("autocomplete", "new-password");
      const inputs = this.$refs.otpInput.$el.querySelectorAll(".otp-input");
      inputs.forEach((i) => i.setAttribute("autocomplete", "new-password"));
    },
    setUserPin(value) {
      this.form.pin = value;
      this.showPinModal = false;
      this.processRequest();
    },
    async sumTotalAmountSterling() {
      let totalAmount = 0;
      let sterlingInterbankAmount = 0;

      await this.receiverAccounts.forEach((receiver) => {
        totalAmount += +receiver.amount;
        return totalAmount;
      });

      await this.receiverAccounts.forEach((receiver) => {
        if (
          receiver.otherBeneficiaryType === "New Beneficiary" ||
          receiver.otherBeneficiaryType === "Saved Beneficiary"
        ) {
          sterlingInterbankAmount += +receiver.amount;
          return sterlingInterbankAmount;
        }
      });

      if (sterlingInterbankAmount !== 0) {
        let payload = {
          amount: sterlingInterbankAmount,
        };

        this.loadingTwo = true;

        api
          .getInterBankTransferFee(payload)
          .then((response) => {
            this.loadingTwo = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let charges = response.Data;
              this.fees.feeAmount = charges.feeAmount;
              this.fees.vat = charges.vat;
              this.fees.totalcharge = charges.totalcharge;
              this.fees.sumTotal = totalAmount + charges.totalcharge;
            } else {
              this.$message({
                showClose: true,
                message: `${
                  responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                }`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.loadingTwo = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000,
            });
          });
      } else {
        this.fees.vat = 0;
        this.fees.feeAmount = 0;
        this.fees.sumTotal = totalAmount;
      }
    },
    async sumTotalAmountWallet() {
      let totalAmount = 0;
      let walletInterbankAmount = 0;

      await this.receiverAccounts.forEach((receiver) => {
        totalAmount += +receiver.amount;
        return totalAmount;
      });

      await this.receiverAccounts.forEach((receiver) => {
        if (
          receiver.otherBeneficiaryType === "New Beneficiary" ||
          receiver.otherBeneficiaryType === "Saved Beneficiary"
        ) {
          walletInterbankAmount += +receiver.amount;
          return walletInterbankAmount;
        }
      });

      if (walletInterbankAmount !== 0) {
        let payload = {
          amount: walletInterbankAmount,
        };

        this.loadingTwo = true;

        api
          .getInterBankTransferFeeWallet(payload)
          .then((response) => {
            this.loadingTwo = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let charges = response.Data;
              this.fees.feeAmount = charges.feeAmount;
              this.fees.vat = charges.vat;
              this.fees.totalcharge = charges.totalcharge;
              this.fees.sumTotal = totalAmount + charges.totalcharge;
            } else {
              this.$message({
                showClose: true,
                message: `${
                  responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                }`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.loadingTwo = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000,
            });
          });
      } else {
        this.fees.vat = 0;
        this.fees.feeAmount = 0;
        this.fees.sumTotal = totalAmount;
      }
    },
    addReceiver() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let recipient = Object.assign({}, this.form);
      this.receiverAccounts.push(recipient);

      if (this.form.accountType !== "Wallet") {
        this.sumTotalAmountSterling();
      } else if (this.form.accountType === "Wallet") {
        this.sumTotalAmountWallet();
      }

      for (let key in this.form) {
        this.form[key] = "";
      }
      this.submitted = false;
    },
    removeReceiver(index) {
      this.receiverAccounts.splice(index, 1);

      if (this.receiverAccounts.length === 0) {
        this.disabled = false;
      }

      if (this.form.accountType !== "Wallet") {
        this.sumTotalAmountSterling();
      } else if (this.form.accountType === "Wallet") {
        this.sumTotalAmountWallet();
      }
    },
    formatAmount(digit) {
      let num;
      if (typeof digit !== "string") {
        if (typeof digit === "number") {
          num = digit;
        }
      } else num = Number.parseFloat(digit, 10);
      if (typeof num !== undefined) {
        num = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
        return `#${num}`;
      }
    },
    submitForm() {
      this.showPinModal = true;
    },
    processRequest() {
      let intraArr = this.receiverAccounts.filter(
        (receiver) =>
          receiver.beneficiaryType === "New Beneficiary" ||
          receiver.beneficiaryType === "Saved Beneficiary" ||
          receiver.selfBeneficiaryType === "Sterling" ||
          receiver.selfBeneficiaryType === "Wallet" ||
          receiver.beneficiaryType === "Wallet"
      );

      let intraArrPayload = [];

      if (intraArr.length !== 0) {
        intraArrPayload = intraArr.map((receiver) => ({
          FromAccount: receiver.debitNuban,
          ToAccount: receiver.creditNuban,
          DebitCurrency: receiver.debitCurrency,
          CreditCurrency: receiver.creditCurrency,
          Amount: +receiver.amount,
          IsFromWallet: receiver.isFromWallet,
          IsToWallet: receiver.isToWallet,
          IsFX: receiver.isFx,
          Narration: receiver.description,
          Category: receiver.budgetCategory,
        }));
      }

      let interArr = this.receiverAccounts.filter(
        (receiver) =>
          receiver.beneficiaryType === "GoMoney" ||
          receiver.otherBeneficiaryType === "New Beneficiary" ||
          receiver.otherBeneficiaryType === "Saved Beneficiary"
      );

      let interArrPayload = [];

      if (interArr.length !== 0) {
        interArrPayload = intraArr.map((receiver) => ({
          FromAccount: receiver.debitNuban,
          ToAccount: receiver.creditNuban,
          DestinationBankCode:
            receiver.bankCodes[receiver.banks.indexOf(receiver.beneficiaryBank)] ||
            receiver.savedBenBankCode,
          Amount: +receiver.amount,
          IsFromWallet: receiver.isFromWallet,
          Narration: receiver.description,
          Category: receiver.budgetCategory,
        }));
      }

      if (intraArrPayload.length !== 0 && interArrPayload.length === 0) {
        this.handleIntrabankBulkPayment(intraArrPayload);
      } else if (intraArrPayload.length === 0 && interArrPayload.length !== 0) {
        this.handleInterbankBulkPayment(interArrPayload);
      } else if (intraArrPayload.length !== 0 && interArrPayload.length !== 0) {
        this.handleJointBulkPayment(intraArrPayload, interArrPayload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
