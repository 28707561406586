<template>
  <el-dialog
    :visible.sync="showToModal"
    :show-close="false"
    :close-on-click-modal="false"
    style="padding: 0; text-align: left"
    class="dialogue"
  >
    <div class="dialogue__heading">
      <h2>To - Destination</h2>
      <div class="dialogue__close" @click="closeDialogue">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.3 10.0006L19.4873 3.81337C20.1709 3.12974 20.1709 2.02141 19.4873 1.33885L18.6624 0.514004C17.9786 -0.169839 16.8703 -0.169839 16.1877 0.514004L10.0006 6.70106L3.81337 0.512722C3.12974 -0.170907 2.02141 -0.170907 1.33885 0.512722L0.512722 1.33756C-0.170907 2.02141 -0.170907 3.12974 0.512722 3.8123L6.70106 10.0006L0.514004 16.1877C-0.169839 16.8715 -0.169839 17.9799 0.514004 18.6624L1.33885 19.4873C2.02247 20.1709 3.13081 20.1709 3.81337 19.4873L10.0006 13.3L16.1877 19.4873C16.8715 20.1709 17.9799 20.1709 18.6624 19.4873L19.4873 18.6624C20.1709 17.9786 20.1709 16.8703 19.4873 16.1877L13.3 10.0006Z"
            fill="#DB353A"
          />
        </svg>
      </div>
    </div>

    <div class="logtype logtype__two" v-if="types">
      <button class="logtype--one switch-btn" @click="(sterling = true), (types = false)">
        Sterling
      </button>
      <button
        class="logtype--two switch-btn"
        @click="(otherBanks = true), (types = false)"
      >
        Other Bank
      </button>
    </div>

    <!-- Sterling Bank -->
    <div class="sterling" v-if="sterling">
      <div class="sterling__header">
        <h3>Sterling</h3>
        <svg
          @click="toggleSterlingAll"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="30" height="30" rx="15" fill="black" />
          <path
            d="M8.21358 15.7891C8.05941 15.9686 7.9844 16.1972 8.00271 16.4335C8.02102 16.6698 8.1297 16.8848 8.30986 17.0384L12.6826 20.7868C12.845 20.9256 13.0465 21 13.2579 21C13.2839 21 13.3105 20.9988 13.3371 20.9965C13.5763 20.9752 13.7919 20.8612 13.9443 20.6763L21.4234 11.5735C21.5734 11.3903 21.6437 11.16 21.6207 10.9243C21.597 10.6886 21.4842 10.476 21.3011 10.3254L19.9319 9.20072C19.5545 8.89121 18.9957 8.94555 18.685 9.32299L12.9289 16.329L10.6158 14.3473C10.2449 14.0307 9.68494 14.0726 9.36657 14.4435L8.21358 15.7891Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="form__group--three">
        <label for="beneficiaryType" class="form__label">
          <input
            readonly
            type="text"
            id="beneficiaryType"
            name="beneficiaryType"
            placeholder="Beneficiary Type"
            class="form__input hide-caret"
            autocorrect="off"
            spellcheck="false"
            autocomplete="off"
            @focus="toggleSterlingType"
            v-model.lazy="form.beneficiaryType"
        /></label>
        <img src="../../../assets/images/down-arrow.svg" alt="dropdown arrow" />
      </div>

      <div class="sterling__beneficiaries" v-if="sterlingType">
        <button @click="setSterlingAccountType('New Beneficiary')">
          New Beneficiary
        </button>
        <button @click="setSterlingAccountType('Saved Beneficiary')">
          Saved Beneficiary
        </button>
        <button @click="setSterlingAccountType('Wallet')">Wallet</button>
        <button @click="setSterlingAccountType('GoMoney')">GoMoney</button>
        <button @click="setSterlingAccountType('Myself')">Send to Myself</button>
      </div>

      <div class="account-details" v-if="showGetAccount">
        <div class="heading-three" v-if="form.beneficiaryType !== 'Myself'">
          <h2>Account Number</h2>
          <p>Beneficiary's account number</p>
        </div>

        <div class="form" v-if="form.beneficiaryType === 'New Beneficiary'">
          <div class="form__group">
            <label for="accountNumber" class="form__label">
              <input
                type="tel"
                id="accountNumber"
                name="accountNumber"
                placeholder="Account Number"
                class="form__input"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                @input="getAccountInfo"
                v-model="form.accountNumber"
            /></label>
            <loading class="form__input-loading" v-if="loadingTwo" />
          </div>
        </div>

        <div class="form" v-if="form.beneficiaryType === 'Wallet'">
          <div class="form__group">
            <label for="accountNumber" class="form__label">
              <input
                type="tel"
                id="accountNumber"
                name="accountNumber"
                placeholder="Account Number"
                class="form__input"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                @input="getWalletInfoInputChange"
                v-model="form.accountNumber"
            /></label>
            <loading class="form__input-loading" v-if="loadingTwo" />
            <br />
            <button
              class="btn2 btn__red btn__group"
              v-if="form.accountNumber.length > 9 && !loadingTwo && !destinationInfo"
              @click="getWalletInfo"
            >
              Validate
            </button>
          </div>
        </div>

        <div class="form" v-if="form.beneficiaryType === 'GoMoney'">
          <div class="form__group">
            <label for="accountNumber" class="form__label">
              <input
                type="tel"
                id="accountNumber"
                name="accountNumber"
                placeholder="Account Number"
                class="form__input"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                @input="getOtherAccountInfo"
                v-model="form.accountNumber"
            /></label>
            <loading class="form__input-loading" v-if="loadingTwo" />
          </div>
        </div>

        <div class="form" v-if="form.beneficiaryType === 'Myself'">
          <div class="heading-three">
            <h2>My account</h2>
            <p>Choose where the money goes</p>
          </div>

          <section class="sources">
            <div class="sources__wrapper">
              <div
                v-for="account in filteredUserAccounts"
                :key="account.NUBAN"
                @click="sendCardDataTwo(account)"
                class="sources__card"
              >
                <p class="sources__card--top">
                  {{ account.NUBAN }} | {{ account.ACCT_TYPE }}
                </p>
                <div class="sources__card--bottom">
                  <div>
                    {{ account.WorkingBalance | formatCurrencyTwo }}
                    {{ account.Currency_Code }}
                  </div>
                  <span>{{ account.AccountDescp | capitalize }}</span>
                </div>
                <p></p>
                <h2></h2>
              </div>
            </div>
          </section>
        </div>

        <div v-if="showBanksList">
          <div class="heading-three">
            <h2>Bank</h2>
            <p>Choose the bank</p>
          </div>
          <div v-loading="loadingThree" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="form__group--three">
              <label for="beneficiaryBank" class="form__label">
                <select
                  name="beneficiaryBank"
                  id="beneficiaryBank"
                  class="form__input form__select"
                  v-model="form.beneficiaryBank"
                >
                  <option disabled value="">Select Bank</option>
                  <option v-for="(bank, index) in form.banks" :value="bank" :key="index">
                    {{ bank }}
                  </option>
                </select>
              </label>
            </div>
          </div>
        </div>

        <div class="account-confirmation" v-if="destinationInfo">
          <h3>{{ form.fullName | capitalize }}</h3>
          <p>Is this the person?</p>
          <div class="btn__grid-two">
            <button class="btn btn__red btn__group" @click="handleconfirmBeneficiary">
              <loading v-if="loading" />
              <span>Yes</span>
            </button>
            <button class="btn btn__black" @click="destinationInfo = false">No</button>
          </div>
        </div>
      </div>

      <div class="form" v-if="remarks">
        <div class="heading-three">
          <h2>Destination Account</h2>
          <p>Here is where the money goes</p>
        </div>
        <h3>{{ form.destinationAccount | capitalize }}</h3>
      </div>

      <div class="confirmed-beneficiary" v-if="confirmedBeneficiary">
        <div class="form">
          <div class="form__group--three form__checkbox">
            <label for="saveBeneficiary" class="styled-checkbox">
              <input
                type="checkbox"
                name="saveBeneficiary"
                id="saveBeneficiary"
                v-model="form.saveBeneficiary"
              />
              <span class="checkmark"></span>
            </label>
            <span>Save Beneficiary?</span>
          </div>
        </div>
      </div>

      <div class="form" v-if="remarks">
        <div class="heading-three">
          <h2>Remarks</h2>
          <p>Transaction description - 100 chars</p>
        </div>
        <div class="form__group--three">
          <label for="description" class="form__label">
            <textarea
              id="description"
              name="description"
              placeholder="Description"
              class="form__input"
              v-model="form.description"
            ></textarea>
          </label>
        </div>
        <button @click="setBeneficiary" class="btn btn__red btn__full">Confirm</button>
      </div>

      <div
        v-if="savedBeneficiaries"
        v-loading="loadingThree"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="saved-beneficiaries"
      >
        <div
          v-for="beneficiary in form.beneficiariesList"
          :key="beneficiary.beneficiaryId"
          @click="sendBeneficiaryData(beneficiary)"
          class="saved-beneficiaries__beneficiary"
        >
          <h2>{{ beneficiary.beneficiaryAccountName | capitalize }}</h2>
          <p>
            {{ beneficiary.beneficiaryAccountNumber }} |
            {{ beneficiary.beneficiaryBankName | capitalize }}
          </p>
        </div>
      </div>
    </div>

    <!-- Other Banks -->
    <div class="other-banks" v-if="otherBanks">
      <div class="other-banks__header">
        <h3>Other Banks</h3>
        <svg
          @click="toggleOthersAll"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="30" height="30" rx="15" fill="black" />
          <path
            d="M8.21358 15.7891C8.05941 15.9686 7.9844 16.1972 8.00271 16.4335C8.02102 16.6698 8.1297 16.8848 8.30986 17.0384L12.6826 20.7868C12.845 20.9256 13.0465 21 13.2579 21C13.2839 21 13.3105 20.9988 13.3371 20.9965C13.5763 20.9752 13.7919 20.8612 13.9443 20.6763L21.4234 11.5735C21.5734 11.3903 21.6437 11.16 21.6207 10.9243C21.597 10.6886 21.4842 10.476 21.3011 10.3254L19.9319 9.20072C19.5545 8.89121 18.9957 8.94555 18.685 9.32299L12.9289 16.329L10.6158 14.3473C10.2449 14.0307 9.68494 14.0726 9.36657 14.4435L8.21358 15.7891Z"
            fill="white"
          />
        </svg>
      </div>

      <div class="form__group--three">
        <label for="otherBeneficiaryType" class="form__label">
          <input
            readonly
            type="text"
            id="otherBeneficiaryType"
            name="otherBeneficiaryType"
            placeholder="Beneficiary Type"
            class="form__input hide-caret"
            @focus="toggleOtherType"
            v-model="form.otherBeneficiaryType"
        /></label>
        <img src="../../../assets/images/down-arrow.svg" alt="dropdown arrow" />
      </div>

      <div class="sterling__beneficiaries" v-if="otherType">
        <button @click="setOtherAccountType('New Beneficiary')">New Beneficiary</button>
        <button @click="setOtherAccountType('Saved Beneficiary')">
          Saved Beneficiary
        </button>
      </div>

      <div class="account-details" v-if="showGetOtherAccount">
        <div class="heading-three">
          <h2>Account Number</h2>
          <p>Beneficiary's account number</p>
        </div>

        <div class="form" v-if="form.otherBeneficiaryType === 'New Beneficiary'">
          <div class="form__group">
            <label for="accountNumber" class="form__label">
              <input
                type="tel"
                id="accountNumber"
                name="accountNumber"
                placeholder="Account Number"
                class="form__input"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                @input="getOtherAccountInfo"
                v-model="form.accountNumber"
            /></label>
            <loading class="form__input-loading" v-if="loadingTwo" />
          </div>
        </div>

        <div v-if="showBanksList">
          <div class="heading-three">
            <h2>Bank</h2>
            <p>Choose the bank</p>
          </div>
          <div v-loading="loadingThree" element-loading-background="rgba(0, 0, 0, 0.8)">
            <div class="form__group--three">
              <label for="beneficiaryBank" class="form__label">
                <select
                  name="beneficiaryBank"
                  id="beneficiaryBank"
                  class="form__input form__select"
                  v-model="form.beneficiaryBank"
                >
                  <option disabled value="">Select Bank</option>
                  <option v-for="(bank, index) in form.banks" :value="bank" :key="index">
                    {{ bank }}
                  </option>
                </select>
              </label>
            </div>
          </div>
        </div>

        <div class="account-confirmation" v-if="destinationInfo">
          <h3>{{ form.fullName | capitalize }}</h3>
          <p>Is this the person?</p>
          <div class="btn__grid-two">
            <button
              class="btn btn__red btn__group"
              @click="handleconfirmOtherBeneficiary"
            >
              <loading v-if="loading" />
              <span>Yes</span>
            </button>
            <button class="btn btn__black" @click="destinationInfo = false">No</button>
          </div>
        </div>
      </div>

      <div class="form" v-if="remarks">
        <div class="heading-three">
          <h2>Destination Account</h2>
          <p>Here is where the money goes</p>
        </div>
        <h3>{{ form.destinationAccount | capitalize }}</h3>
      </div>

      <div class="confirmed-beneficiary" v-if="confirmedBeneficiary">
        <div class="form">
          <div class="form__group--three form__checkbox">
            <label for="saveBeneficiary" class="styled-checkbox">
              <input
                type="checkbox"
                name="saveBeneficiary"
                id="saveBeneficiary"
                v-model="form.saveBeneficiary"
              />
              <span class="checkmark"></span>
            </label>
            <span>Save Beneficiary?</span>
          </div>
        </div>
      </div>

      <div class="form" v-if="remarks">
        <div class="heading-three">
          <h2>Remarks</h2>
          <p>Transaction description - 100 chars</p>
        </div>
        <div class="form__group--three">
          <label for="description" class="form__label">
            <textarea
              id="description"
              name="description"
              placeholder="Description"
              class="form__input"
              v-model="form.description"
            ></textarea>
          </label>
        </div>
        <button @click="setOtherBeneficiary" class="btn btn__red btn__full">
          Confirm
        </button>
      </div>

      <div
        v-if="savedOtherBeneficiaries"
        v-loading="loadingThree"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        class="saved-beneficiaries"
      >
        <div
          v-for="beneficiary in form.beneficiariesList"
          :key="beneficiary.beneficiaryId"
          @click="sendOtherBeneficiaryData(beneficiary)"
          class="saved-beneficiaries__beneficiary"
        >
          <h2>{{ beneficiary.beneficiaryAccountName | capitalize }}</h2>
          <p>
            {{ beneficiary.beneficiaryAccountNumber }} |
            {{ beneficiary.beneficiaryBankName | capitalize }}
          </p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
// import { required } from "vuelidate/lib/validators";
export default {
  computed: mapState(["userAccounts", "user", "loading"]),
  props: ["showToModal", "filteredUserAccounts"],
  data() {
    return {
      types: true,
      sterling: false,
      sterlingType: false,
      showGetAccount: false,
      savedBeneficiaries: false,
      loadingThree: false,
      loadingTwo: false,
      destinationInfo: false,
      validateButton: false,
      showBanksList: false,
      confirmedAccount: "",
      remarks: false,
      submittedTwo: false,
      confirmedBeneficiary: false,
      savedOtherBeneficiaries: false,
      otherBanks: false,
      otherType: false,
      showGetOtherAccount: false,
      form: {
        banks: [],
        bankCodes: [],
        beneficiaryType: "",
        beneficiariesList: null,
        beneficiaryDetails: "",
        accountNumber: "",
        fullName: "",
        selfBeneficiaryType: "",
        creditCurrency: "NGN",
        creditNuban: "",
        destinationAccount: "",
        saveBeneficiary: "",
        description: "",
        beneficiaryBank: "",
        otherBeneficiaryType: "",
        otherAccountInfo: "",
        savedBenBankCode: "",
        receiverBank: "",
      },
    };
  },
  validations: {
    form: {
      // description: { required },
    },
  },
  watch: {
    confirmedAccount: function (newValue) {
      if (newValue !== "") {
        this.form.destinationAccount = `${newValue.NUBAN} - ${newValue.AccountTitle}`;
      }
    },
    "form.beneficiaryBank": function (newValue) {
      if (newValue !== "") {
        this.form.receiverBank = newValue;
        this.handleOtherAccountInfo();
      }
    },
  },
  methods: {
    closeDialogue() {
      this.types = true;
      this.sterling = false;
      this.sterlingType = false;
      this.form.beneficiaryType = "";
      this.showGetAccount = false;
      this.savedBeneficiaries = false;
      this.otherBanks = false;
      this.otherType = false;
      this.form.otherBeneficiaryType = "";
      this.showGetOtherAccount = false;
      this.savedOtherBeneficiaries = false;
      this.confirmedBeneficiary = false;
      this.remarks = false;

      this.$emit("close");
    },
    toggleSterlingType() {
      this.sterlingType = true;
      this.showGetAccount = false;
      this.savedBeneficiaries = false;
    },
    toggleSterlingAll() {
      this.types = true;
      this.sterling = false;
      this.sterlingType = false;
      this.form.beneficiaryType = "";
      this.showGetAccount = false;
      this.savedBeneficiaries = false;
      this.remarks = false;
    },
    toggleOtherType() {
      this.otherType = true;
      this.showGetOtherAccount = false;
      this.savedOtherBeneficiaries = false;
    },
    toggleOthersAll() {
      this.types = true;
      this.otherBanks = false;
      this.otherType = false;
      this.form.otherBeneficiaryType = "";
      this.showGetOtherAccount = false;
      this.savedOtherBeneficiaries = false;
      this.confirmedBeneficiary = false;
      this.remarks = false;
    },
    setSterlingAccountType(value) {
      this.destinationInfo = false;
      this.form.accountNumber = "";

      let payload = {
        userId: this.user.profileInfo.SMID,
      };

      switch (value) {
        case "New Beneficiary":
          this.form.beneficiaryType = "New Beneficiary";
          this.showGetAccount = true;
          break;
        case "Saved Beneficiary":
          this.form.beneficiaryType = "Saved Beneficiary";
          this.savedBeneficiaries = true;

          this.loadingThree = true;

          api
            .getSterlingBeneficiary(payload)
            .then((response) => {
              this.loadingThree = false;
              let responseStatus = response.Status;
              let responseMessage = response.Message;

              if (responseStatus) {
                let beneficiariesList = response.Data.beneficaries;
                if (beneficiariesList.length > 0) {
                  this.form.beneficiariesList = beneficiariesList;
                } else {
                  this.savedBeneficiaries = false;
                  this.$message({
                    showClose: true,
                    message: `No beneficiary found.`,
                    type: "info",
                    duration: 10000,
                  });
                }
              } else {
                this.$message({
                  showClose: true,
                  message: `${
                    responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                  }`,
                  type: "error",
                  duration: 10000,
                });
              }
            })
            .catch((error) => {
              this.loadingThree = false;
              this.$message({
                showClose: true,
                message: `Error fetching beneficiary`,
                type: "error",
                duration: 10000,
              });
              throw error;
            });

          break;
        case "Wallet":
          this.form.beneficiaryType = "Wallet";
          this.showGetAccount = true;
          break;
        case "GoMoney":
          this.form.beneficiaryType = "GoMoney";
          this.showGetAccount = true;
          break;
        case "Myself":
          this.form.beneficiaryType = "Myself";
          this.showGetAccount = true;
          break;

        default:
          break;
      }

      this.sterlingType = false;
    },
    setOtherAccountType(value) {
      let payload = {
        userId: this.user.profileInfo.SMID,
      };

      switch (value) {
        case "New Beneficiary":
          this.form.otherBeneficiaryType = "New Beneficiary";
          this.showGetOtherAccount = true;
          break;
        case "Saved Beneficiary":
          this.form.otherBeneficiaryType = "Saved Beneficiary";
          this.savedOtherBeneficiaries = true;

          this.loadingThree = true;

          api
            .getNonSterlingBeneficiary(payload)
            .then((response) => {
              this.loadingThree = false;
              let responseStatus = response.Status;
              let responseMessage = response.Message;

              if (responseStatus) {
                let beneficiariesList = response.Data.beneficaries;
                if (beneficiariesList.length > 0) {
                  this.form.beneficiariesList = beneficiariesList;
                } else {
                  this.savedOtherBeneficiaries = false;
                  this.$message({
                    showClose: true,
                    message: `No beneficiary found.`,
                    type: "info",
                    duration: 10000,
                  });
                }
              } else {
                this.$message({
                  showClose: true,
                  message: `${
                    responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                  }`,
                  type: "error",
                  duration: 10000,
                });
              }
            })
            .catch((error) => {
              this.loadingThree = false;
              this.$message({
                showClose: true,
                message: `Error fetching beneficiary`,
                type: "error",
                duration: 10000,
              });
              throw error;
            });

          break;

        default:
          break;
      }

      this.otherType = false;
    },
    getAccountInfo(e) {
      e.preventDefault();
      let accountNum = e.target.value;
      this.form.accountNumber = accountNum;

      if (accountNum.length === 10) {
        let payload = {
          nuban: accountNum,
        };

        this.loadingTwo = true;

        api
          .getAccountDetailsByNuban(payload)
          .then((response) => {
            this.loadingTwo = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let userDetails = response.Data.BankAccountFullInfo;
              this.form.beneficiaryDetails = userDetails;
              this.form.fullName = userDetails.AccountTitle;
              this.destinationInfo = true;
              this.form.receiverBank = "Sterling Bank";
            } else {
              this.$message({
                showClose: true,
                message: `${
                  responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                }`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.loadingTwo = false;
            this.$message({
              showClose: true,
              message: `Error fetching account details`,
              type: "error",
              duration: 10000,
            });
            throw error;
          });
      }
    },
    getWalletInfoInputChange(e) {
      e.preventDefault();
      let accountNum = e.target.value;

      if (accountNum.length < 10) {
        this.destinationInfo = false;
        this.validateButton = false;
      }
    },
    getWalletInfo() {
      // e.preventDefault();
      // let accountNum = e.target.value;
      // this.form.accountNumber = accountNum;

      if (this.form.accountNumber.length >= 10) {
        let payload = {
          mobile: this.form.accountNumber,
        };

        this.loadingTwo = true;
        this.destinationInfo = false;

        api
          .getWalletDetails(payload)
          .then((response) => {
            this.loadingTwo = false;
            let responseStatus = response.Status;
            let responseMessage = response.Message;

            if (responseStatus) {
              let userWallet = response.Data.data;

              let walletAccount = [];
              walletAccount.push(userWallet);
              let walletAccountMod = walletAccount.map((item) => ({
                ACCT_TYPE: "Wallet",
                NUBAN: item.nuban,
                WorkingBalance: item.availablebalance,
                CUR_CODE: item.CURRENCYCODE,
                AccountTitle: item.fullname,
              }));

              this.form.beneficiaryDetails = walletAccountMod[0];
              this.form.fullName = userWallet.fullname;
              this.destinationInfo = true;
              this.form.receiverBank = "Sterling Bank";
            } else {
              this.$message({
                showClose: true,
                message: `${
                  responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
                }`,
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((error) => {
            this.loadingTwo = false;
            this.$message({
              showClose: true,
              message: `${error}`,
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    handleOtherAccountInfo() {
      let payload = {
        destinationbankcode: this.form.bankCodes[
          this.form.banks.indexOf(this.form.beneficiaryBank)
        ],
        destinationaccount: this.form.accountNumber,
      };

      this.loadingTwo = true;

      api
        .handleInterbankNameEnquiry(payload)
        .then((response) => {
          this.loadingTwo = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let userDetails = response.Data;

            let userAccount = [];
            userAccount.push(userDetails);
            let userAccountMod = userAccount.map((item) => ({
              NUBAN: this.form.accountNumber,
              AccountTitle: item.accountname,
            }));

            this.form.otherAccountInfo = userDetails;
            this.form.beneficiaryDetails = userAccountMod[0];
            this.form.fullName = userDetails.accountname;
            this.destinationInfo = true;
            this.form.receiverBank = this.form.beneficiaryBank;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loadingTwo = false;
          this.$message({
            showClose: true,
            message: `Could not verify beneficiary details`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    getOtherAccountInfo(e) {
      e.preventDefault();
      let accountNum = e.target.value;
      this.form.accountNumber = accountNum;

      if (accountNum.length === 10) {
        this.handleGetBanks();
        this.showBanksList = true;
      }
    },
    handleGetBanks() {
      this.loadingThree = true;

      api
        .getBanks()
        .then((response) => {
          this.loadingThree = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus) {
            let banksList = response.Data.banks;

            banksList.map((data) => {
              this.form.banks.push(data.oldbankcode);
            });
            banksList.map((data) => {
              this.form.bankCodes.push(data.bankcode);
            });
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$message({
            showClose: true,
            message: `Error fetching banks`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    sendCardDataTwo(account) {
      account.ACCT_TYPE === "Wallet"
        ? (this.form.selfBeneficiaryType = "Wallet")
        : (this.form.selfBeneficiaryType = "Sterling");

      let selectedAccount = [];
      selectedAccount.push(account);

      let selectedAccountMod = selectedAccount.map((item) => ({
        NUBAN: item.NUBAN,
        AccountTitle: item.AccountDescp,
        CUR_CODE: item.Currency_Code,
      }));

      this.form.beneficiaryDetails = selectedAccountMod[0];
      this.confirmedAccount = this.form.beneficiaryDetails;
      this.form.receiverBank = "Sterling Bank";

      this.showGetAccount = false;
      this.remarks = true;
    },
    handleconfirmBeneficiary() {
      this.confirmedBeneficiary = true;
      this.showGetAccount = false;
      this.confirmedAccount = this.form.beneficiaryDetails;
      this.remarks = true;
    },
    handleconfirmOtherBeneficiary() {
      this.confirmedBeneficiary = true;
      this.showGetOtherAccount = false;
      this.confirmedAccount = this.form.beneficiaryDetails;
      this.remarks = true;
    },
    setBeneficiary() {
      this.submittedTwo = true;

      // if (this.$v.form.description.$invalid) {
      //   return;
      // }

      this.form.creditNuban = this.form.beneficiaryDetails.NUBAN;
      this.form.creditCurrency = this.form.beneficiaryDetails.CUR_CODE;
      // console.log(this.form.creditCurrency);
      this.$emit("destinationDetails", this.form);
    },
    setOtherBeneficiary() {
      this.submittedTwo = true;

      // if (this.$v.form.description.$invalid) {
      //   return;
      // }

      this.form.creditNuban = this.form.beneficiaryDetails.NUBAN;

      this.$emit("destinationDetails", this.form);
    },
    sendBeneficiaryData(beneficiary) {
      let payload = {
        nuban: beneficiary.beneficiaryAccountNumber,
      };

      this.loadingThree = true;

      api
        .getAccountDetailsByNuban(payload)
        .then((response) => {
          this.loadingThree = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let userDetails = response.Data.BankAccountFullInfo;
            this.form.beneficiaryDetails = userDetails;
            this.confirmedAccount = this.form.beneficiaryDetails;
            this.form.receiverBank = beneficiary.beneficiaryBankName;

            this.savedBeneficiaries = false;
            this.remarks = true;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loadingThree = false;
          this.$message({
            showClose: true,
            message: `Error fetching account details`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
    sendOtherBeneficiaryData(beneficiary) {
      let payload = {
        destinationbankcode: beneficiary.beneficiaryBankCode,
        destinationaccount: beneficiary.beneficiaryAccountNumber,
      };

      this.loadingThree = true;

      api
        .handleInterbankNameEnquiry(payload)
        .then((response) => {
          this.loadingThree = false;
          let responseStatus = response.Status;
          let responseMessage = response.Message;

          if (responseStatus) {
            let userDetails = response.Data;

            let userAccount = [];
            userAccount.push(userDetails);
            let userAccountMod = userAccount.map((item) => ({
              NUBAN: beneficiary.beneficiaryAccountNumber,
              AccountTitle: item.accountname,
            }));

            this.form.savedBenBankCode = beneficiary.beneficiaryBankCode;
            this.form.receiverBank = beneficiary.beneficiaryBankName;
            this.form.otherAccountInfo = userDetails;
            this.form.beneficiaryDetails = userAccountMod[0];
            this.confirmedAccount = this.form.beneficiaryDetails;

            this.savedOtherBeneficiaries = false;
            this.remarks = true;
          } else {
            this.$message({
              showClose: true,
              message: `${
                responseMessage.charAt(0).toUpperCase() + responseMessage.slice(1)
              }`,
              type: "error",
              duration: 10000,
            });
          }
        })
        .catch((error) => {
          this.loadingThree = false;
          this.$message({
            showClose: true,
            message: `Could not verify beneficiary details`,
            type: "error",
            duration: 10000,
          });
          throw error;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
.btn2 {
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 1.2rem 3.8rem;
  font-family: Red Hat Display;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.4rem;
  letter-spacing: 0.05em;
  transition: opacity 0.3s;
  margin: 1.5rem auto;
}
</style>
