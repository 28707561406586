<template>
  <section class="transfer">
    <div class="logtype">
      <button
        class="logtype--one switch-btn active-login"
        @click="selected = 'app-single-transfer'"
      >
        Single Transfer
      </button>
      <button
        class="logtype--two switch-btn"
        @click="selected = 'app-bulk-transfer'"
      >
        Bulk Transfer
      </button>
    </div>

    <transition name="slide-fade">
      <keep-alive>
        <component :is="selected"></component>
      </keep-alive>
    </transition>
  </section>
</template>

<script>
import SingleTransfer from "@/components/Transfers/LocalTransfers/SingleTransfers.vue";
import BulkTransfer from "@/components/Transfers/LocalTransfers/BulkTransfers.vue";

export default {
  components: {
    "app-single-transfer": SingleTransfer,
    "app-bulk-transfer": BulkTransfer
  },
  data() {
    return {
      selected: "app-single-transfer"
    };
  },
  mounted() {
    // this.filteredUserAccounts = [...this.userAccounts];

    const btns = document.querySelectorAll(".switch-btn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function() {
        let current = document.getElementsByClassName("active-login");

        if (current.length > 0) {
          current[0].className = current[0].className.replace(
            " active-login",
            ""
          );
        }
        this.className += " active-login";
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
